import React from 'react';
import styled from 'styled-components';
import { between } from 'polished';
import { FaStar } from 'react-icons/fa';

const StyledTestimonial = styled.div`
	display: flex;
	margin-top: ${between('35px', '45px')};
	margin-bottom: ${between('5px', '40px')};
`;

const Person = styled.div`
	min-width: ${between('80px', '150px')};
	text-align: center;
	margin-left: ${between('-5px', '107px')};
	margin-right: ${between('10px', '30px')};

	img {
		width: ${between('60px', '120px')};
		height: ${between('60px', '120px')};
		border: solid 3px ${props => props.theme.colors.warm_grey_300};
		border-radius: 50%;
	}

	.name {
		margin: 10px 0 15px;
		display: block;
	}

	.stars {
		font-size: ${between('14px', '26px')};

		svg {
			fill: ${props => props.theme.colors.golden_400};
		}
	}
`;

const Feedback = styled.div`
	background: ${props => props.theme.colors.white};
	max-width: 600px;
	position: relative;
	font-size: ${between('14px', '15px')};
	padding: ${between('8px', '16px')};
	align-self: flex-start;
	line-height: ${between('20px', '26px')};
	border: 1px solid ${props => props.theme.colors.warm_grey_300};
	border-radius: 5px;

	&:after,
	&:before {
		right: 100%;
		top: 50%;
		border: solid transparent;
		content: '';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-right-color: ${props => props.theme.colors.white};
		border-width: 10px;
		margin-top: -10px;
	}

	&:before {
		border-color: rgba(194, 225, 245, 0);
		border-right-color: ${props => props.theme.colors.warm_grey_300};
		border-width: 12px;
		margin-top: -12px;
	}
`;

const Testimonial = () => (
	<StyledTestimonial>
		<Person>
			<img src="/joanna.jpg" alt="Joanna" loading="lazy" />

			<span className="name"><b>Joanna</b>, wokalistka</span>

			<div className="stars">
				<FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
			</div>
		</Person>

		<Feedback>
			Nie muszę już sama szukać nowych klientów, dzięki portalowi to klienci znajdują mnie, a kontakt przez aplikacje mobilne sprawia, że szybko finalizuję umowy. Zapotrzebowanie okazało się większe niż myślałam, mogę teraz wybierać najciekawsze zlecenia. <b>Zdecydowanie polecam!</b>
		</Feedback>
	</StyledTestimonial>
);

export default Testimonial;
