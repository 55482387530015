import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { between, transparentize } from 'polished';
import { FaLongArrowAltRight, FaInfoCircle } from 'react-icons/fa';

import Auth from '../utils/auth.js';
import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import HeaderDescription from '../components/header-description';
import SEO from '../components/seo';
import Container from '../components/container';
import PostForm from '../components/post-form';
import Testimonial from '../components/testimonial';
import Alert from '../components/alert';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('280px', '470px')};
`;

const StyledContainer = styled(Container)`
	max-width: ${props => props.theme.breakpoints.sm};

	.legal {
		margin-top: 20px;
		font-size: 13px;
		line-height: 20px;
	}

	@media (min-width: ${props => props.theme.breakpoints.md}) {
		background: ${props => props.theme.colors.white};
		margin-top: ${between('1px', '-100px')};
		padding: 24px;
		position: relative;
		z-index: 100;
		box-shadow: 0 1px 50px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)},
			0 2px 15px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)};
	}
`;

const AdditionalSection = styled.section`
	background: ${props => props.theme.colors.warm_grey_100};
	position: relative;
	padding-top: ${between('15px', '80px')};
	padding-bottom: ${between('30px', '40px')};
`;

const StyledAlert = styled(Alert)`
	a,
	.link {
		color: ${props => props.theme.colors.golden_500};
		cursor: pointer;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
`;

class AddOfferPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			categories: [],
			towns: [],
			isUserLogged: false,
		};

		props.data.allMysqlCategory.edges.forEach(({ node }) => {
			const obj = { value: node.slug, label: node.label_nominative };
			this.state.categories.push(obj);
		});

		props.data.allMysqlTown.edges.forEach(({ node }) => {
			const obj = { value: node.slug, label: node.label_nominative };
			this.state.towns.push(obj);
		});

		this.logIn = this.logIn.bind(this);
		this.handleLogInKeyPress = this.handleLogInKeyPress.bind(this);
	};

	componentDidMount() {
		const auth = new Auth();
		if (!auth.isAuthenticated()) return;

		this.setState({ isUserLogged: true });
	}

	logIn() {
		const auth = new Auth();
		auth.login('/dodaj-oferte/');
	}

	handleLogInKeyPress(event) {
		if (event.charCode === 13 || event.charCode === 32) this.logIn();
	}

	render() {
		return (
			<Page>
				<SEO title="Dodaj ofertę" description="Pokaż czym się zajmujesz i zdobądź nowych klientów. Dołącz do grona usługodawców ślubnych i weselnych." />
				<StyledHeaderSection hero="/hero-profile.jpg">
					<HeaderFront>
						<Navigation hideAddOfferButton={true} />
						<Container>
							<HeaderHeading>Dodaj ofertę</HeaderHeading>
							{this.state.isUserLogged &&
								<HeaderDescription>Pokaż czym się zajmujesz i zdobądź nowych klientów</HeaderDescription>
							}

							{!this.state.isUserLogged &&
								<HeaderDescription>
									<button type="button" onClick={this.logIn}>Zaloguj się <FaLongArrowAltRight /></button>
								</HeaderDescription>
							}
						</Container>
					</HeaderFront>
				</StyledHeaderSection>

				<StyledContainer>
					{this.state.isUserLogged &&
						<>
							<PostForm categories={this.state.categories} towns={this.state.towns} />

							<p className="legal">
								Dodając ofertę akceptuję <Link to="/regulamin/">Regulamin</Link> portalu.<br />
								Przyjmuję do wiadomości, że dane osobowe wykorzystane będą zgodnie z <Link to="/polityka-prywatnosci/">Polityką prywatności</Link>.
							</p>
						</>
					}

					{!this.state.isUserLogged &&
						<StyledAlert>
							<FaInfoCircle className="icon" />
							<span className="link" role="button" tabIndex="0" onClick={this.logIn} onKeyPress={this.handleLogInKeyPress}>Zaloguj się</span>, aby dodać nową ofertę
						</StyledAlert>
					}
				</StyledContainer>

				<AdditionalSection className="slant--top slant--bottom">
					<Container>
						<Testimonial />
					</Container>
				</AdditionalSection>
			</Page>
		);
	};
};

export default AddOfferPage;

export const query = graphql`
	query AddOfferPage {
		allMysqlTown {
			edges {
				node {
					slug
					label_nominative
				}
			}
		}

		allMysqlCategory {
			edges {
				node {
					slug
					label_nominative
				}
			}
		}
	}
`;
